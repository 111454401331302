<template>
  <section :id="id" :class="`text ${cls}`">
    <div class="text_text typo-rhythm typo-format" :class="`-${alignment}`">
      <StructuredText v-if="text.value?.document" :data="<any>text" :render-link-to-record="renderLinkToRecord" />
    </div>
  </section>
</template>

<script setup lang="ts">
import './text.css'
import type { TText } from './text.type'
import { StructuredText } from 'vue-datocms'
import { h } from 'vue'

const props = withDefaults(defineProps<TText>(), {})

function renderLinkToRecord(api: any) {
  const vnode = h(
    'a',
    {
      class: 'link',
      href: api.record.slug,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    api.children,
  )
  return vnode
}

const id = props.advanced?.id ?? ''
const cls = props.advanced?.class ?? ''
</script>